<template>
    <div>
        <a-sidebar></a-sidebar>
        <div class="main-content" id="panel">
            <a-mobile-sidebar></a-mobile-sidebar>
            <a-header title="My Profile" class="bg-light text-white">
                <div class="col-lg-7 col-md-10">
                    <h1 class="display-2 text-dark text-capitalize">{{user.username}}</h1>
                    <p class="text-dark mt-0 mb-5">Hi {{user.first_name}} {{user.last_name}}, your last login was: {{user.last_login_at}}</p>
                    <!-- <a href="#!" class="btn btn-neutral">Edit profile</a> -->
                </div>
            </a-header>
            <a-content>
                
            </a-content>
            <a-footer></a-footer>
        </div>
    </div>
</template>

<script>
import AdminContentVue from '../components/Admin/AdminContent.vue'
import AdminFooterVue from '../components/Admin/AdminFooter.vue'
import AdminHeaderVue from '../components/Admin/AdminHeader.vue'
import AdminMobileNavVue from '../components/Nav/AdminMobileNav.vue'
import AdminSideBarVue from '../components/Nav/AdminSideBar.vue'
//import NoButtonWhiteCardVue from '../../components/Cards/NoButtonWhiteCard.vue';
export default {
    name: 'AdminProfile',
    components: {
        'a-header': AdminHeaderVue,
        'a-mobile-sidebar': AdminMobileNavVue,
        'a-sidebar': AdminSideBarVue,
        'a-content': AdminContentVue,
        'a-footer': AdminFooterVue,
        //'form-card': NoButtonWhiteCardVue
    },
    data() {
        return{
            user:null
        }
    },
    mounted(){
        if (!this.authToken) {
            this.$router.push('/login');
        }
        this.user = JSON.parse(localStorage.mydata)        
    },
    methods:{
    },
}
</script>